import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import useSWR from "swr";
import {
  USERS_USER_TYPE_VALUE_ADMIN,
  USERS_USER_TYPE_VALUE_CUSTOMER,
} from "../../constants/users";
import { usersCan, usersIsRoot } from "../../helpers/users";
import useApiFetcher from "../../hooks/useApiFetcher";
import generateApiUri from "../../libraries/utils/generateApiUrl";

/**
 * @typedef {object} AuthContextValue
 * @property {import("../../types/User").UserBase} [user]
 * @property {import("../../types/Admin").Admin} [userAdmin]
 * @property {import("../../types/Customer").Customer } [userCustomer]
 * @property {( data?: any) => void} mutate
 * @property {boolean} isRoot
 * @property {boolean} loading
 * @property {boolean} isValidating
 * @property {boolean} logged
 * @property {(user: import("../../types/User").UserBase) => void} login
 * @property {(params: { authorizations: any, environmentId: any }) => boolean} userCan
 * @property {() => Promise<void>} logout
 * @property {boolean} isLoggingOut
 */

/** @type {AuthContextValue} */
const DefaultValue = {
  loading: false,
  isValidating: false,
  logged: false,
  login: () => {},
  mutate: () => {},
  userCan: () => false,
  isRoot: false,
  logout: () => Promise.resolve(),
  isLoggingOut: false,
};

/** @type {React.Context<AuthContextValue>} */
export const AuthContext = createContext(DefaultValue);

/**
 * @typedef {object} Props
 * @property {import("../../types/User").UserBase} [initialUser]
 * @property {string[]} fields
 * @property {import("react").ReactNode} children
 */
/**
 * @param {Props} props
 */
function AuthProvider({ initialUser, fields = [], children }) {
  const apiFetcher = useApiFetcher();

  const [isLoggingOut, setIsLoggingOut] = useState(false);

  /** @type {import("swr").SWRResponse<import("../../types/Api/ApiResponse").ApiResponse<import("../../types/User").UserBase | []>>} */
  const swrResponse = useSWR(
    generateApiUri({
      id: "@auth.me",
      query: {
        fields,
      },
    }),
    apiFetcher,
    {
      revalidateOnMount: false,
      revalidateOnFocus: true,
      refreshInterval: 0,
      dedupingInterval: 30000,
      fallbackData: {
        data: initialUser,
      },
    },
  );

  const user = useMemo(() => {
    return Array.isArray(swrResponse.data?.data)
      ? undefined
      : swrResponse.data?.data;
  }, [swrResponse.data?.data]);

  const logged = Boolean(user);

  const isRoot = usersIsRoot({ user });

  /**
   * Connecte l’utilisateur.
   * @param {import("../../types/User").UserBase} user
   */
  const login = useCallback(
    function (user) {
      swrResponse.mutate(user);
    },
    [swrResponse],
  );

  const userCan = useCallback(
    function ({ authorizations, environmentId }) {
      return usersCan({
        authorizations,
        environmentId,
        user,
      });
    },
    [user],
  );

  const logout = useCallback(() => {
    setIsLoggingOut(true);
    return apiFetcher(
      generateApiUri({
        id: "@auth.logout",
      }),
      {
        method: "POST",
      },
    );
  }, [apiFetcher]);

  useEffect(() => {
    if (!user) {
      setIsLoggingOut(false);
    }
  }, [user]);

  const value = useMemo(() => {
    /** @type {AuthContextValue} */
    const value = {
      loading: swrResponse.isLoading,
      isValidating: swrResponse.isValidating,
      logged,
      mutate: swrResponse.mutate,
      login,
      userCan: userCan,
      isRoot,
      logout,
      isLoggingOut,
      user,
    };

    const type = user?.user_type;

    if (user) {
      switch (type) {
        case USERS_USER_TYPE_VALUE_ADMIN:
          value.userAdmin = /** @type {import("../../types/Admin").Admin} */ (
            user
          );
          break;
        case USERS_USER_TYPE_VALUE_CUSTOMER:
          value.userCustomer =
            /** @type {import("../../types/Customer").Customer} */ (user);
          break;

        default:
          break;
      }
    }

    return value;
  }, [
    swrResponse.isLoading,
    swrResponse.isValidating,
    swrResponse.mutate,
    logged,
    login,
    userCan,
    isRoot,
    logout,
    isLoggingOut,
    user,
  ]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
