// deps
import { FormattedMessage } from "react-intl";
import {
  IoCogOutline,
  IoDocumentTextOutline,
  IoWalletOutline,
  IoHomeOutline,
  IoStatsChartOutline,
  IoCalendarNumberOutline,
  IoNewspaperOutline,
} from "react-icons/io5";
import uniqid from "uniqid";

// utils
import generateAdminPath from "@raiden/library-ui/libraries/utils/generateAdminPath";

// constants
import {
  METRICS_METRIC_VALUE_ROOM_AVAILABILITY_KPI,
  METRICS_METRIC_VALUE_ROOM_BOOKING_KPI,
  METRICS_METRIC_VALUE_SUBSCRIPTION_KPI,
} from "@raiden/library-ui/constants/metrics";
import {
  AUTHORIZATIONS_AUTHORIZATION_VALUE_COUNTRIES_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_GROUPS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_ENVIRONMENTS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_DEPARTMENTS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_CITIES_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_ACCOUNTS_RECORDS_EXPORTS_VALUE_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_HOTELS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_CUSTOMERS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_DELIBERATIONS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_NOTIFICATIONS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_SEPAS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_SEPAS_EXPORT,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_PARTNERS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_METRICS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_EXPORT_REMOTE,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_AVAILABILITIES_REMOTES_EXPORT,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_CARTS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_HOTELS_EXPORT,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_EXPORT,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_EXPORT,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_BOOKINGS_EXPORT,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_INSURANCES_CONTRACTS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_INSURANCES_EXPORT_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_CUSTOMERS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_INVOICES_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_BANK_ACCOUNTS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_CUSTOMERS_EXPORT,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_INVOICES_EXPORT,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_REMITTANCES_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_AVAILABILITIES_REMOTES_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_CARTS_BOOKING_ISSUES_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_AMENITIES_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_COMPOSITIONS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_REGIONS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_REDIRECTIONS_URLS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_SLIDESHOWS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_CAMPAIGNS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_CUSTOMERS_EXPORT,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_HIGHLIGHTS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CONTACTS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_CARTS_BOOKING_REVIEW_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_PICTURES_CATEGORIES_VIEW_ANY,
} from "@raiden/library-ui/constants/authorizations";
import { Box } from "@chakra-ui/react";
import {
  postsTypePluralMessage,
  POSTS_TYPE_LIST,
} from "@raiden/library-ui/constants/posts";
import {
  campaignsTypePluralMessage,
  CAMPAIGNS_TYPE_LIST,
} from "@raiden/library-ui/constants/campaigns";
import { MdPerson } from "react-icons/md";

/** @type {import("../containers/SignedInLayout/Menu/MenuItem").Item[]} */
const _MENU_ITEMS = [
  {
    title: <FormattedMessage defaultMessage="Accueil" />,
    icon: IoHomeOutline,
    href: generateAdminPath({ id: "dashboard" }),
  },
  {
    title: <FormattedMessage defaultMessage="Paramètres" />,
    icon: IoCogOutline,
    children: [
      {
        title: <FormattedMessage defaultMessage="Environnements" />,
        href: generateAdminPath({ id: "@environments.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_ENVIRONMENTS_VIEW_ANY,
          }),
      },

      {
        title: <FormattedMessage defaultMessage="Départements" />,
        href: generateAdminPath({ id: "@departments.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_DEPARTMENTS_VIEW_ANY,
          }),
      },

      {
        title: <FormattedMessage defaultMessage="Régions" />,
        href: generateAdminPath({ id: "@regions.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_REGIONS_VIEW_ANY,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Villes" />,
        href: generateAdminPath({ id: "@cities.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_CITIES_VIEW_ANY,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Pays" />,
        href: generateAdminPath({ id: "@countries.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_COUNTRIES_VIEW_ANY,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Groupes d'utilisateurs" />,
        href: generateAdminPath({ id: "@usersGroups.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_GROUPS_VIEW_ANY,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Administrateurs" />,
        href: generateAdminPath({ id: "@usersAdmins.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_VIEW_ANY,
          }),
      },
      {
        title: (
          <FormattedMessage defaultMessage="Caractéristiques d'hébergement" />
        ),
        href: generateAdminPath({ id: "@amenities.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_AMENITIES_VIEW_ANY,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Types de pièces" />,
        href: generateAdminPath({ id: "@compositions.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_COMPOSITIONS_VIEW_ANY,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Catégories de photos" />,
        href: generateAdminPath({ id: "@roomPicturesCategories.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_PICTURES_CATEGORIES_VIEW_ANY,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Redirections" />,
        href: generateAdminPath({ id: "@admin.redirections.urls.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_REDIRECTIONS_URLS_VIEW_ANY,
          }),
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Système" />,
    icon: IoDocumentTextOutline,
    children: [
      {
        title: <FormattedMessage defaultMessage="Laravel Horizon" />,
        href: generateAdminPath({ id: "@internalHorizon.view" }),
        validator: ({ isRoot }) => isRoot,
      },
      {
        title: <FormattedMessage defaultMessage="Laravel Telescope" />,
        href: generateAdminPath({ id: "@internalTelescope.view" }),
        validator: ({ isRoot }) => isRoot,
      },
      {
        title: <FormattedMessage defaultMessage="État de l’application" />,
        href: generateAdminPath({ id: "@healthStatus.view" }),
        validator: ({ isRoot }) => isRoot,
      },
      {
        title: <FormattedMessage defaultMessage="Rentals united" />,
        href: generateAdminPath({ id: "@rentals.viewAny" }),
        validator: ({ isRoot }) => isRoot,
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Contenus" />,
    icon: IoNewspaperOutline,
    children: [
      ...POSTS_TYPE_LIST.map((postType) => ({
        title: (
          <Box sx={{ "&::first-letter": { textTransform: "uppercase" } }}>
            <FormattedMessage
              {...postsTypePluralMessage}
              values={{ type: postType.id }}
            />
          </Box>
        ),
        href: generateAdminPath({
          id: "@posts.viewAny",
          parameters: {
            postType: postType.id,
          },
        }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_VIEW_ANY,
          }),
      })),
      {
        title: <FormattedMessage defaultMessage="Menus" />,
        href: generateAdminPath({ id: "@menus.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_VIEW_ANY,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Diaporamas" />,
        href: generateAdminPath({ id: "@slideshows.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_SLIDESHOWS_VIEW_ANY,
          }),
      },
      ...CAMPAIGNS_TYPE_LIST.map((type) => ({
        title: (
          <Box sx={{ "&::first-letter": { textTransform: "uppercase" } }}>
            <FormattedMessage
              {...campaignsTypePluralMessage}
              values={{ type: type.id }}
            />
          </Box>
        ),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_CAMPAIGNS_VIEW_ANY,
          }),
        href: generateAdminPath({
          id: "@campaigns.viewAny",
          parameters: {
            campaignType: type.id,
          },
        }),
      })),
      {
        title: <FormattedMessage defaultMessage="Coups de coeur" />,
        href: generateAdminPath({ id: "@highlights.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_HIGHLIGHTS_VIEW_ANY,
          }),
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Distribution" />,
    icon: IoHomeOutline,
    children: [
      {
        title: <FormattedMessage defaultMessage="Structures" />,
        href: generateAdminPath({ id: "@hotels.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_HOTELS_VIEW_ANY,
          }),
        isActive: ({ router }) => {
          return (
            router?.pathname?.startsWith?.(
              generateAdminPath({ id: "@hotels.viewAny" }),
            ) &&
            router?.pathname !== generateAdminPath({ id: "@hotels.export" })
          );
        },
      },
      {
        title: <FormattedMessage defaultMessage="Hébergements" />,
        href: generateAdminPath({ id: "@rooms.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_VIEW_ANY,
          }),
        isActive: ({ router }) => {
          return (
            router?.pathname?.startsWith?.(
              generateAdminPath({ id: "@rooms.viewAny" }),
            ) && router?.pathname !== generateAdminPath({ id: "@rooms.export" })
          );
        },
      },
      {
        title: <FormattedMessage defaultMessage="Propriétaires" />,
        href: generateAdminPath({ id: "@customers.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_CUSTOMERS_VIEW_ANY,
          }),
        isActive: ({ router }) => {
          return (
            router?.pathname?.startsWith?.(
              generateAdminPath({
                id: "@customers.viewAny",
              }),
            ) &&
            router?.pathname !==
              generateAdminPath({ id: "@customers.export" }) &&
            router?.pathname !==
              generateAdminPath({ id: "@customers.contacts.search" }) &&
            router?.pathname !==
              generateAdminPath({
                id: "@customers.contacts.categories.search",
              }) &&
            router?.pathname !==
              generateAdminPath({
                id: "@customers.contacts.categories.custom_attributes.search",
              })
          );
        },
      },
      {
        title: <FormattedMessage defaultMessage="iCal" />,
        href: generateAdminPath({
          id: "@ical.viewAny",
        }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_AVAILABILITIES_REMOTES_VIEW_ANY,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Taxe de séjour" />,
        href: generateAdminPath({ id: "@deliberations.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_DELIBERATIONS_VIEW_ANY,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Partenaires" />,
        href: generateAdminPath({ id: "@partners.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_PARTNERS_VIEW_ANY,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Formules" />,
        href: generateAdminPath({ id: "@subscriptions.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_VIEW_ANY,
          }),
        isActive: ({ router }) => {
          return (
            router?.pathname?.startsWith?.(
              generateAdminPath({ id: "@subscriptions.viewAny" }),
            ) &&
            !router?.pathname?.startsWith?.(
              generateAdminPath({ id: "@subscriptionsCustomers.viewAny" }),
            )
          );
        },
      },
      {
        title: <FormattedMessage defaultMessage="Abonnements" />,
        href: generateAdminPath({ id: "@subscriptionsCustomers.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_CUSTOMERS_VIEW_ANY,
          }),
        isActive: ({ router }) => {
          return (
            router?.pathname?.startsWith?.(
              generateAdminPath({ id: "@subscriptionsCustomers.viewAny" }),
            ) &&
            router?.pathname !==
              generateAdminPath({ id: "@subscriptionsCustomers.export" })
          );
        },
      },
    ],
  },

  {
    title: <FormattedMessage defaultMessage="Réservations" />,
    icon: IoCalendarNumberOutline,
    children: [
      {
        title: <FormattedMessage defaultMessage="Réservations" />,
        href: generateAdminPath({ id: "@cartsBookings.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_CARTS_VIEW_ANY,
          }),
        isActive: ({ router }) => {
          return (
            (router?.pathname?.startsWith?.(
              generateAdminPath({ id: "@cartsBookings.viewAny" }),
            ) ||
              router?.asPath?.startsWith?.(
                generateAdminPath({
                  id: "@cartsBookings.view",
                  parameters: {
                    bookingId: router?.query?.bookingId,
                    cartId: router?.query?.cartId,
                  },
                }),
              )) &&
            router?.pathname !==
              generateAdminPath({ id: "@cartsBookingsIssues.viewAny" }) &&
            router?.pathname !==
              generateAdminPath({ id: "@cartsBookingsReviews.viewAny" })
          );
        },
      },
      {
        title: <FormattedMessage defaultMessage="Litiges" />,
        href: generateAdminPath({ id: "@cartsBookingsIssues.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_CARTS_BOOKING_ISSUES_VIEW_ANY,
          }),
        isActive: ({ router }) => {
          return (
            router?.pathname?.startsWith?.(
              generateAdminPath({ id: "@cartsBookingsIssues.viewAny" }),
            ) &&
            router?.pathname !==
              generateAdminPath({ id: "@cartsBookings.viewAny" })
          );
        },
      },
      {
        title: <FormattedMessage defaultMessage="Avis client" />,
        href: generateAdminPath({ id: "@cartsBookingsReviews.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_CARTS_BOOKING_REVIEW_VIEW_ANY,
          }),
        isActive: ({ router }) => {
          return (
            router?.pathname?.startsWith?.(
              generateAdminPath({ id: "@cartsBookingsReviews.viewAny" }),
            ) &&
            router?.pathname !==
              generateAdminPath({ id: "@cartsBookings.viewAny" })
          );
        },
      },
      {
        title: <FormattedMessage defaultMessage="Contrats assurance" />,
        href: generateAdminPath({ id: "@insurances.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_INSURANCES_CONTRACTS_VIEW_ANY,
          }),
        isActive: ({ router }) => {
          return (
            router?.pathname?.startsWith?.(
              generateAdminPath({ id: "@insurances.viewAny" }),
            ) &&
            router?.pathname !== generateAdminPath({ id: "@insurances.export" })
          );
        },
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Finance" />,
    icon: IoWalletOutline,
    children: [
      {
        title: <FormattedMessage defaultMessage="Exports comptables" />,
        href: generateAdminPath({ id: "@accountRecordsExports.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_ACCOUNTS_RECORDS_EXPORTS_VALUE_VIEW_ANY,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Paiements" />,
        href: generateAdminPath({ id: "@payments.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_VIEW_ANY,
          }),
        isActive: ({ router }) => {
          return (
            router?.pathname?.startsWith?.(
              generateAdminPath({ id: "@payments.viewAny" }),
            ) &&
            router?.pathname !==
              generateAdminPath({ id: "@payments.export" }) &&
            !router?.pathname?.startsWith?.(
              generateAdminPath({ id: "@paymentsRemittances.viewAny" }),
            )
          );
        },
      },
      {
        title: <FormattedMessage defaultMessage="Remises bancaires" />,
        href: generateAdminPath({ id: "@paymentsRemittances.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_REMITTANCES_VIEW_ANY,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="SEPA" />,
        href: generateAdminPath({ id: "@sepas.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_SEPAS_VIEW_ANY,
          }),
        isActive: ({ router }) => {
          return (
            router?.pathname?.startsWith?.(
              generateAdminPath({ id: "@sepas.viewAny" }),
            ) && router?.pathname !== generateAdminPath({ id: "@sepas.export" })
          );
        },
      },
      {
        title: <FormattedMessage defaultMessage="Factures" />,
        href: generateAdminPath({ id: "@invoices.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_INVOICES_VIEW_ANY,
          }),
        isActive: ({ router }) => {
          return (
            router?.pathname?.startsWith?.(
              generateAdminPath({ id: "@invoices.viewAny" }),
            ) &&
            router?.pathname !== generateAdminPath({ id: "@invoices.export" })
          );
        },
      },
      {
        title: <FormattedMessage defaultMessage="Comptes bancaires" />,
        href: generateAdminPath({ id: "@bankAccounts.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_BANK_ACCOUNTS_VIEW_ANY,
          }),
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Clients" />,
    icon: MdPerson,
    children: [
      {
        title: <FormattedMessage defaultMessage="Contacts clients" />,
        href: generateAdminPath({ id: "@customers.contacts.search" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CONTACTS_VIEW_ANY,
          }),
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Exports" />,
    icon: IoDocumentTextOutline,
    children: [
      {
        title: <FormattedMessage defaultMessage="Structures" />,
        href: generateAdminPath({ id: "@hotels.export" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_HOTELS_EXPORT,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Hébergements" />,
        href: generateAdminPath({ id: "@rooms.export" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_EXPORT,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Propriétaires" />,
        href: generateAdminPath({ id: "@customers.export" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_CUSTOMERS_EXPORT,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Réservations" />,
        href: generateAdminPath({ id: "@bookings.export" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_BOOKINGS_EXPORT,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Paiements" />,
        href: generateAdminPath({ id: "@payments.export" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_EXPORT,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Territoire" />,
        href: generateAdminPath({ id: "@sepas.export" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_SEPAS_EXPORT,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="iCal plateforme" />,
        href: generateAdminPath({ id: "@remotes.export" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_AVAILABILITIES_REMOTES_EXPORT,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="iCal" />,
        href: generateAdminPath({ id: "@exportRemoteUrl.export" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_EXPORT_REMOTE,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Assurances" />,
        href: generateAdminPath({ id: "@insurances.export" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_INSURANCES_EXPORT_VIEW_ANY,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Abonnements" />,
        href: generateAdminPath({ id: "@subscriptionsCustomers.export" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_CUSTOMERS_EXPORT,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Factures" />,
        href: generateAdminPath({ id: "@invoices.export" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_INVOICES_EXPORT,
          }),
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Statistiques" />,
    icon: IoStatsChartOutline,
    children: [
      {
        title: <FormattedMessage defaultMessage="Notifications" />,
        href: generateAdminPath({ id: "@notifications.viewAny" }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations:
              AUTHORIZATIONS_AUTHORIZATION_VALUE_NOTIFICATIONS_VIEW_ANY,
          }),
      },
      {
        title: <FormattedMessage defaultMessage="Disponibilités" />,
        href: generateAdminPath({
          id: "@metrics.viewAny",
          query: {
            metric: METRICS_METRIC_VALUE_ROOM_AVAILABILITY_KPI,
          },
        }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_METRICS_VIEW_ANY,
          }),
        isActive: ({ router }) => {
          return (
            generateAdminPath({
              id: "@metrics.viewAny",
            }) === router?.pathname &&
            router?.query.metric === METRICS_METRIC_VALUE_ROOM_AVAILABILITY_KPI
          );
        },
      },
      {
        title: <FormattedMessage defaultMessage="Réservations" />,
        href: generateAdminPath({
          id: "@metrics.viewAny",
          query: {
            metric: METRICS_METRIC_VALUE_ROOM_BOOKING_KPI,
          },
        }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_METRICS_VIEW_ANY,
          }),
        isActive: ({ router }) => {
          return (
            generateAdminPath({
              id: "@metrics.viewAny",
            }) === router?.pathname &&
            router?.query.metric === METRICS_METRIC_VALUE_ROOM_BOOKING_KPI
          );
        },
      },
      {
        title: <FormattedMessage defaultMessage="Abonnements" />,
        href: generateAdminPath({
          id: "@metrics.viewAny",
          query: {
            metric: METRICS_METRIC_VALUE_SUBSCRIPTION_KPI,
          },
        }),
        validator: ({ canPerform }) =>
          canPerform({
            authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_METRICS_VIEW_ANY,
          }),
        isActive: ({ router }) => {
          return (
            generateAdminPath({
              id: "@metrics.viewAny",
            }) === router?.pathname &&
            router?.query.metric === METRICS_METRIC_VALUE_SUBSCRIPTION_KPI
          );
        },
      },
    ],
  },
];

/**
 * adds uniqid & depth to each item
 * @param {import("../containers/SignedInLayout/Menu/MenuItem").Item[]} items
 * @param {number} [depth]
 * @returns {import("../containers/SignedInLayout/Menu/MenuItem").Item[]}
 */
function prepare(items, depth = 0) {
  return items.map((item) => ({
    ...item,
    id: uniqid(),
    depth,
    children: item.children && prepare(item.children, depth + 1),
  }));
}

export const MENU_ITEMS = prepare(_MENU_ITEMS);
